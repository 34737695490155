import React from 'react';
import { createMarkup } from '../../../utils/Utils';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

const ServiceItem = (
    { slug, title, image, description }:
        {
            slug: string,
            title: string,
            image: string,
            description?: string
        }
) => {
    const location = useLocation();
    return (
        <div className="service-item">
            <div className="custom-service-item">
                <div className="image-wrapper">
                    <img src={image} alt="image" />
                </div>
                <div className="content-wrapper p-4">
                    {location?.pathname?.startsWith("/services") ? (
                        <h4 className="m-0 pb-2 title">
                            {title}
                        </h4>) : (
                        <h4
                            className="m-0 pb-2 title"
                            dangerouslySetInnerHTML={createMarkup(title, true, 18)}
                        />)}
                    {location?.pathname?.startsWith("/services") && (
                        <p
                            className="m-0 py-2 description"
                            dangerouslySetInnerHTML={createMarkup(description, true, 230)}
                        />)}
                    <div className="pt-2 btn-wrapper">
                        <NavLink
                            to={`/services/${slug}`}
                            title="Prendre rdv"
                            className="btn custom-btn"
                        >
                            En savoir plus
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceItem;