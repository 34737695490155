import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import Banner from "../../assets/images/appImages/img-banner.png";
import Deco from "../../assets/images/appImages/deco.png";

function BannerHomepage() {
  const location = useLocation();
  return (
    <section className={`
      section-banner-homepage 
      ${location?.pathname?.startsWith("/services") && 'service-banner'}
      `}
    >
      <div className="row align-items-center">
        <div className="col-md-8 col-left-banner-accueil mb-3">
          <div className="content-col-left-banner-accueil">
            <h2 className="mb-0 title-home-page">
              {location?.pathname?.startsWith("/services")
                ? "Nos Services" : "Votre concept store"}
            </h2>
            <h2 className="mb-0 title-home-page">
              {location?.pathname?.startsWith("/services")
                ? "" : "beauté et bien-être"}
            </h2>
            {!location?.pathname?.startsWith("/services") &&
              <h2 className="title-home-page">
                100% femmes
              </h2>
            }
            <div className="content-text-home-page-banner mt-2 mb-5">
            </div>
            <div className="container-btn-banner-accueil">
              {!location?.pathname?.startsWith("/services") &&
                <NavLink className="btn btn-title-home-page" to="/produits">
                  Voir nos produits
                </NavLink>}
            </div>
          </div>
        </div>
        <div className="col-md-4 col-right-banner-accueil">
          <div className="content-col-right-banner-accueil">
            {/*  */}
            <div className="container-img-deco-banner">
              {/*  */}
            </div>
          </div>
        </div>
      </div>
      <div className="blank_filter"></div>
    </section>
  );
}

export default BannerHomepage;
