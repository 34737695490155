import React from 'react';
import FrontFooter from '../../shared/FrontFooter/FrontFooter';
import FrontHeader from '../../shared/FrontHeader/FrontHeader';
import Fade from "react-reveal/Fade";
import { HiDocumentDownload, HiOutlineArrowLeft } from 'react-icons/hi';
import { useNavigate, useParams } from 'react-router-dom';
import { Accordion, Carousel } from 'react-bootstrap';
import { MdOutlineAddBox } from 'react-icons/md';
import { data } from "./ServicePage";
import { createMarkup } from '../../../utils/Utils';
import { Link } from 'react-router-dom';



const ServicePageDetail = () => {
    const pm = useParams<{ slug: string }>();
    const service = data?.find((el) => el?.slug === pm?.slug);
    const navigate = useNavigate();

    return (
        <div className="landing-page-component">
            <FrontHeader />
            <div className="page-content">
                <div className="container-easy-market mb-5">
                    <Fade bottom>
                        <div
                            className="content-back-to pb-5 pt-3"
                            onClick={(e) => {
                                e?.preventDefault();
                                navigate(-1);
                            }}
                        >
                            <HiOutlineArrowLeft style={{ fontSize: 18 }} />
                            <span className="ps-2 go-back-btn">RETOUR</span>
                        </div>
                    </Fade>
                    <div className="service-describe-content">
                        <div className="row row-product-item-page mb-4 pb-4">
                            <div className="col-lg-6 col-md-12 col-left-product-item-page d-flex">
                                <div className="content-col-left-product-item-page container-product-item-page on-service">
                                    <div className="video-carousel-container">
                                        <div id="serviceVideoCarousel" className="carousel slide">
                                            <div className="carousel-inner">
                                                {!!service?.videos &&
                                                    service?.videos?.map((el, k) => (
                                                        <div className={`carousel-item ${el?.id === 1 ? 'active' : ''}`}>
                                                            <div className="video-wrapper">
                                                                <iframe
                                                                    width="100%"
                                                                    height="315"
                                                                    src={el?.video}
                                                                    title="YouTube video player"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerPolicy="strict-origin-when-cross-origin"
                                                                    allowFullScreen
                                                                ></iframe>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                            <div className="carousel-indicators">
                                                {!!service?.videos &&
                                                    service?.videos?.map((el, k) => (
                                                        <span
                                                            role="button"
                                                            data-bs-target="#serviceVideoCarousel"
                                                            data-bs-slide-to={`${el?.id - 1}`}
                                                            className={`active`}
                                                            aria-current={`${el?.id === 1 ? 'true' : 'false'}`}
                                                            aria-label={`Slide ${el?.id}`}
                                                        >
                                                            {el?.id}
                                                        </span>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-left-product-item-page d-flex">
                                <div className="content-col-left-product-item-page container-product-item-page">
                                    <Fade bottom>
                                        <h1 className="name-prduct mb-2">
                                            {service?.title}
                                        </h1>
                                    </Fade>
                                    <Fade bottom>
                                        <p className="category-product-name mb-1">
                                            {service?.type}
                                        </p>
                                    </Fade>
                                    <div className="custom_container container-product-description pb-4">
                                        <Fade bottom>
                                            <p className="text-describe-product-item-detail py-3"
                                                dangerouslySetInnerHTML={createMarkup(service?.description, false)}
                                            />
                                        </Fade>
                                    </div>
                                    <div className="container-btn-add-to-cart-product mt-4">
                                        <div className="content-btn-commande">
                                            <Link
                                                title="Prendre rdv"
                                                role="button"
                                                className="btn btn-theme-annim w-100"
                                                to={`${service?.link && service?.link}`}
                                            >
                                                <span className="pe-2">
                                                    Prendre rendez-vous
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {service?.items && (
                        <div className="service-accordion">
                            <Accordion defaultActiveKey="item-1">
                                {service?.items?.map((el, k) => (
                                    <div className="custom-accordion-wrappper" key={k}>
                                        <Accordion.Item eventKey={el?.id}>
                                            <Accordion.Header>
                                                <h5 className="m-0 custom-title">
                                                    {el?.title}
                                                </h5>
                                                {service?.items[service?.items?.length - 1]?.id === el?.id ? (
                                                    <Link
                                                        to={`${el?.file && el?.file}`}
                                                        download
                                                        className="btn download_btn"
                                                    >
                                                        <span>Téléchager le fichier</span>
                                                        <span><HiDocumentDownload size={22} /></span>
                                                    </Link>
                                                ) : (
                                                    <span>
                                                        <MdOutlineAddBox size={24} />
                                                    </span>
                                                )}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div dangerouslySetInnerHTML={createMarkup(el?.content)} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </div>
                                ))}
                            </Accordion>
                        </div>)}

                </div>
            </div>
            <div style={{ padding: '3rem 0' }}></div>
            <FrontFooter />
        </div>
    )
}

export default ServicePageDetail;
