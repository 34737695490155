import React from 'react';
import FrontFooter from '../../shared/FrontFooter/FrontFooter';
import FrontHeader from '../../shared/FrontHeader/FrontHeader';
import BannerHomepage from '../../HomePage/BannerHomepage';
import ServiceItem from './ServiceItem';
import image1 from "../../../assets/images/appImages/service1.png";
import image2 from "../../../assets/images/appImages/service2.png";
import Fade from "react-reveal/Fade";
import AliceCarousel from 'react-alice-carousel';
import { responsiveI } from '../../HomePage/HomePage';


const ServicePage = () => {
    return (
        <div className="landing-page-component">
            <FrontHeader />
            <div className="page-content">
                <BannerHomepage />
                <div className="container-easy-market mb-5">
                    <h2 className="custom-page-title title-home-page">
                        Pourquoi choisir le royaume de bilkis
                    </h2>
                    <div className="custom-description">
                        <ul className="mb-3 custom-list">
                            <li className="mb-2 list-item">
                                <strong>Un cadre apaisant et sécurisé :</strong> Notre espace est conçu pour vous offrir une expérience de bien-être dans une atmosphère sereine et accueillante. Nous accordons une grande importance à l'hygiène et à la sécurité pour que vous vous sentiez en confiance.
                            </li>
                            <li className="mb-2 list-item">
                                <strong>Une praticienne qualifiée et empathique :</strong> Coumba, notre unique praticienne, est une infirmière diplômée d'État, née et grandie en France. Son approche bienveillante et respectueuse de vos besoins spécifiques vous garantit des soins de haute qualité.
                            </li>
                            <li className="list-item">
                                <strong>Des services adaptés à vos besoins :</strong> Que vous cherchiez à soulager des douleurs, à vous détendre après une longue journée ou à découvrir des pratiques de bien-être traditionnelles, nous avons des services adaptés à vos besoins.
                            </li>
                        </ul>
                    </div>
                    <div className="what-we-offer who-we-are pt-5">
                        <div className="container">
                            <div className="row row-services">
                                {data && data?.map((el, i) => (
                                    <div className="col-lg-12 mb-5 col-wrapper" key={i}>
                                        <ServiceItem
                                            slug={el?.slug}
                                            title={el?.title}
                                            image={el?.image}
                                            description={el?.smallDescription}
                                            key={el?.id}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ padding: '3rem 0' }}></div>
            <FrontFooter />
        </div>
    )
}

export default ServicePage;



export const ServiceComponent = () => {
    return (
        <>
            <div className="container-home-page py-5 container-home-page-nos-produits-carousel">
                <div className="content-title-home-page pb-1">
                    <div className="title-home-page-container">
                        <Fade bottom>
                            <h3 className="title-home-page-theme text-center">
                                Nos services
                            </h3>
                        </Fade>
                    </div>
                </div>
                <div className="carrousel-container">
                    <AliceCarousel
                        mouseTracking
                        responsive={responsiveI}
                        autoPlayStrategy="default"
                        controlsStrategy="alternate"
                        autoPlay={true}
                        infinite={true}
                        keyboardNavigation={true}
                        autoPlayInterval={6000}
                    >
                        {data &&
                            data?.slice(-2)?.map((el, i) => (
                                <div className="col-lg-12 mb-5 col-wrapper" key={i}>
                                    <ServiceItem
                                        slug={el?.slug}
                                        title={el?.title}
                                        image={el?.image}
                                        description={el?.description}
                                        key={el?.id}
                                    />
                                </div>
                            ))}
                    </AliceCarousel>
                </div>
            </div>
        </>
    );
}


export const data = [
    {
        id: 1,
        slug: "hijama-et-massage-aux-pierres-chaudes",
        title: "Hijama et Massage aux Pierres Chaudes",
        type: "services",
        image: image1,
        link: "https://calendly.com/souare-coumba/",
        videos: [
            {
                id: 1,
                image: image1,
                video: "https://player.vimeo.com/video/998150193",
            },
        ],
        smallDescription: "La hijama, également connue sous le nom de \"cupping therapy\", est une technique ancestrale de médecine douce qui consiste à utiliser des ventouses pour améliorer la circulation sanguine, éliminer les toxines et soulager diverses douleurs. Chez le Royaume de Bilkis, nous pratiquons la hijama de manière douce et professionnelle, en veillant à votre confort et à votre bien-être.",
        description: "<p><strong>La Hijama : Un Soin Traditionnel et Apaisant </strong></p><p><strong>Qu'est-ce que la hijama ?</strong></p><p>La hijama, également connue sous le nom de \"cupping therapy\", est une technique ancestrale de médecine douce qui consiste à utiliser des ventouses pour améliorer la circulation sanguine, éliminer les toxines et soulager diverses douleurs. Chez le Royaume de Bilkis, nous pratiquons la hijama de manière douce et professionnelle, en veillant à votre confort et à votre bien-être.</p> </br> <p><strong>Le Massage aux Pierres Chaudes : Une Évasion Relaxante</strong></p><p><strong>Qu'est-ce que le massage aux pierres chaudes</strong></p><p>Le massage aux pierres chaudes est une technique de relaxation qui utilise des pierres volcaniques chauffées pour apaiser les muscles tendus et favoriser la relaxation profonde. Les pierres sont placées sur des points spécifiques du corps et utilisées par notre praticienne pour masser délicatement votre peau.</p> ",
        items: [
            {
                id: "item-1",
                title: "Pourquoi choisir la hijama ?",
                content: "Soulagement des douleurs: La hijama peut aider à soulager les douleurs musculaires, les migraines, et d'autres inconforts physiques </br> Détoxification : En améliorant la circulation sanguine, la hijama aide à éliminer les toxines du corps. </br> Bien-être général: Cette pratique traditionnelle contribue à un sentiment de bien-être et de relaxation. </br> Traitement des pathologies féminines: La hijama est particulièrement bénéfique pour les femmes, aidant à traiter diverses pathologies gynécologiques telles que les douleurs menstruelles, les troubles hormonaux et les problèmes de fertilité.",
            },
            {
                id: "item-2",
                title: "La hijama fait-elle mal ?",
                content: "Nous comprenons que la peur de la douleur peut être un obstacle. Rassurez-vous, la hijama, lorsqu'elle est pratiquée correctement, est une technique très douce. Notre praticienne, Coumba, est une infirmière diplômée d'État, née et grandie en France. Elle veille à ce que le processus soit aussi confortable que possible, en utilisant des méthodes adaptées pour minimiser toute gêne.",
            },
            {
                id: "item-3",
                title: "Pourquoi choisir le massage aux pierres chaudes ?",
                content: "Détente musculaire : La chaleur des pierres aide à détendre les muscles et à soulager les tensions. </br> Réduction du stress : Ce massage procure une profonde sensation de relaxation et de bien-être. </br> Amélioration de la circulation : Le massage aux pierres chaudes stimule la circulation sanguine, contribuant ainsi à une meilleure oxygénation des tissus.",
            },
            {
                id: "item-4",
                title: "Est-ce que le massage aux pierres chaudes fait mal ?",
                content: "Le massage aux pierres chaudes est connu pour être extrêmement apaisant. La chaleur douce des pierres permet une relaxation profonde sans provoquer de douleur. Coumba, notre praticienne expérimentée, est formée pour ajuster la température des pierres et la pression du massage selon vos préférences et votre confort.",
            },
            {
                id: "item-5",
                title: "Nos tarifs",
                content: "",
                file: "https://drive.google.com/file/d/1VOckcXBjAFyckwPAr0KAvWNiIyBBNK-b/view?usp=drive_link"
            },
        ]
    },
    {
        id: 2,
        slug: "microlocks-et-microtwists",
        title: "Microlocks et Microtwists",
        type: "services",
        image: image2,
        link: "https://calendly.com/royaume-de-bilkis/rendes-vous-microlocks-microtwists-ou-tresses",
        videos: [
            {
                id: 1,
                image: image2,
                video: "https://player.vimeo.com/video/996765595",
            },
            {
                id: 2,
                image: image2,
                video: "https://player.vimeo.com/video/996765643",
            },
            {
                id: 3,
                image: image2,
                video: "https://player.vimeo.com/video/996764436",
            },
        ],
        smallDescription: "Notre mission est de vous aider à mettre en avant la beauté naturelle de vos cheveux crépus et à porter fièrement votre couronne. Nos services sont spécialement conçus pour sublimer vos cheveux naturels tout en les protégeant. Nous proposons plusieurs prestations",
        description: "<p>Notre mission est de vous aider à mettre en avant la beauté naturelle de vos cheveux crépus et à porter fièrement votre couronne. Nos services sont spécialement conçus pour sublimer vos cheveux naturels tout en les protégeant. Nous proposons plusieurs prestations:</p> </br>  <ul> <li><strong>soins sur cheveux naturels</strong></li> <li><strong>microlocks</strong></li> <li><strong>microtwists</strong></li> <li><strong>nattes</strong></li></ul>",
        items: [
            {
                id: "item-1",
                title: "Les microlocks : Une solution pratique et esthétique",
                content: "<p><strong>Qu'est-ce que les microlocks ?</strong></p><p>Les microlocks sont une technique de coiffure protectrice qui consiste à créer de petites locks uniformes. Cette méthode permet d'obtenir une coiffure durable, facile à entretenir et esthétique.</p> </br>   <p><strong>Pourquoi choisir les microlocks ?</strong></p> <div><p><strong>Durabilité</strong>:  Les microlocks sont une solution à long terme, permettant de maintenir une coiffure impeccable pendant des mois</p> <p><strong>Facilité d'entretien</strong>: Une fois installés, les microlocks nécessitent peu d'entretien quotidien</p> <p><strong>Versatilité</strong>: Ils permettent de réaliser diverses coiffures et sont parfaits pour les femmes actives.</p> </div> </br>   <div> <p><strong>Services proposés:</strong></p> <p><strong>Installation des microlocks</strong>: Notre experte procède à la création des microlocks avec soin et précision pour garantir un résultat uniforme et élégant.</p> <p><strong>Resserrage des microlocks</strong>: Pour maintenir la beauté et la santé de vos locks, nous offrons un service de resserrage régulier afin d'éviter le relâchement des racines.</p> </div>",
            },
            {
                id: "item-2",
                title: "Les microtwists : élégance et flexibilité",
                content: "<p><strong>Qu'est-ce que les microtwists ?</strong></p><p>Les microtwists sont des petites tresses réalisées avec vos cheveux naturels en départ vanille. Cette technique protectrice permet de préserver la santé de vos cheveux tout en offrant une coiffure élégante et facile à entretenir.</p> </br>   <p><strong>Pourquoi choisir les microtwists ?</strong></p> <div><p><strong>Protection des cheveux</strong>: Les microtwists aident à protéger vos cheveux des agressions extérieures et de la casse.</p>  <p><strong>Polyvalence</strong>: Ils offrent de nombreuses possibilités de styles et peuvent être portés lâchés ou coiffés de différentes manières.</p> <p><strong>Entretien minimal</strong>: Cette coiffure nécessite peu d'entretien quotidien et reste belle pendant des semaines.</p> <p><strong>Flexibilité</strong>: Contrairement aux microlocks, les microtwists peuvent être retirés facilement si besoin. Si vous décidez de les transformer en locks, nous pouvons effectuer un resserrage au crochet pour une transition en douceur.</p> </div> </br>   <div> <p><strong>Services proposés:</strong></p> <p><strong>Soins capillaires</strong>:  Nos soins sont conçus pour renforcer et revitaliser vos cheveux crépus, en utilisant des produits naturels et adaptés à votre type de cheveux. </p> <p><strong>Nattes sur cheveux crépus</strong>: Que ce soit pour un style quotidien ou pour une occasion spéciale, nos nattes protectrices sont réalisées avec soin pour sublimer vos cheveux naturels.</p> </div>",
            },
            {
                id: "item-3",
                title: "Nos tarifs",
                content: "",
                file: "https://drive.google.com/file/d/1W81EhLQeLlM55QNNR8sCcx-9S-hWthda/view?usp=drive_link"
            },
        ]
    },
];

