import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import illustration from "../../../assets/images/appImages/illustration.png";
import { TeamData } from "./data";
import { Link } from "react-router-dom";

const CustomBeautyTitle = ({ title }: { title: string }) => {
  return <h2 className="custom-esb-title">{title}</h2>;
};

const TeamCard = ({
  photo,
  name,
  role,
  desc
}: {
  photo?: string;
  name: string;
  role: string;
  desc: string
}) => {
  return (
    <div className="team-card">
      {!!photo && (
        <span className="photo-wrapper">
          <img loading="lazy" src={photo} alt="photo" />
        </span>)}
      <span className="name">{name}</span>
      <span className="role">{role}</span>
      <span dangerouslySetInnerHTML={{ __html: desc }} className="pt-3 text-center" />
    </div>
  );
};

function WhoWeAre() {
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content who-we-are-page pb-5 pb-5">
        <h1 className="custom-page-title">Qui sommes-nous ?</h1>
        <div className="who-we-are">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-4">
                <div className="illustration-container">
                  <img loading="lazy" src={illustration} alt="image" />
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <CustomBeautyTitle title="Qui sommes nous?" />
                <h3 className="custom-title">Bilkis</h3>
                <div className="custom-content">
                  Le Royaume de Bilkis est bien plus qu'un simple concept store. Il incarne une vision ambitieuse, centrée sur la valorisation
                  de la beauté au naturel et la promotion de marques féminines dans le secteur de la beauté et du bien-être.
                  <br />
                  Le Sénégal, riche en diversité culturelle et créativité, est le cadre parfait pour donner vie à notre concept store.
                  <br /><br />
                  Ce concept store est bien plus qu'un lieu de commerce ; c'est la destination incontournable pour les amatrices de beauté
                  naturelle et un incubateur pour les femmes entrepreneures du Sénégal. Nous croyons en l'autonomisation des femmes, en favorisant
                  l'entreprenariat féminin, et en encourageant la confiance en soi à travers l'appréciation de la beauté authentique.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="plateform-founders">
          <div className="container">
            <CustomBeautyTitle title="Qui sommes-nous" />
            <h3 className="custom-title">Les fondateurs</h3>
            <div className="row pt-4">
              {!!TeamData &&
                TeamData.map((member, key) => {
                  return (
                    <div className="col-md-6 col-lg-4 mb-4 card-wrapper" key={key}>
                      <TeamCard
                        name={member.name}
                        role={member.role}
                        desc={member?.desc}
                        key={member.id}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: '5rem 0' }}></div>
      <FrontFooter />
    </div>
  );
}

export default WhoWeAre;
