import photo1 from "../../../assets/images/appImages/member-1.png";
import photo2 from "../../../assets/images/appImages/member-2.png";
import photo3 from "../../../assets/images/appImages/member-3.png";

export const TeamData = [
  {
    id: 1,
    name: "Salimata SYLLA",
    role: "Co-fondatrice et Chef des Opérations",
    desc: "Fondatrice de Saly_Consulting et entrepreneuse depuis 12 ans. <br /> Diplômée en Master Langues Étrangères option Communication et Événementiel. <br />Experte en stratégie business et gestion de projet pour les entrepreneures"
  },

];
